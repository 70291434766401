import {Component} from "react";
import {inject, observer} from "mobx-react";
import withRouter, {IRouterProps} from "../../../utlis/withRouter";
import {IStore} from "../../../stores";
import {Avatar, Button, Modal, NavBar, ProgressBar} from "antd-mobile";
import {DungeonBattleEntity} from "../../../models/DungeonBattleEntity";
import {APIHelper} from "../../../utlis/APIHelper";
import {ModalUtils} from "../../../utlis/modalUtils";
import {WuXing} from "../../../enums/WuXing";
import {GetPlayerIcon} from "../../../utlis/AssetsUtils";
import {BattleStatus} from "../../../enums/battleStatus";
import BattleDungeon = APIHelper.BattleDungeon;
import GetDungeonInfo = APIHelper.GetDungeonInfo;

interface DungeonsBattleProps extends IRouterProps, IStore {

}

interface DungeonsBattleState {
    level: number;
    name: string;
    currentStage: number;
    dungeon: DungeonBattleEntity;
    logcat: Array<{
        selfHP: number;
        targetHP: number;
        log: string;
    }>
    renderLog: Array<{
        selfHP: number;
        targetHP: number;
        log: string;
    }>
    playerTotalHp: number;
    masterTotalHp: number;
    playerHp: number;
    masterHp: number;
    drops: string;
    battleWin: boolean
    battleStatus: BattleStatus;
    finished: boolean

}

@inject("player", "config")
@observer
class DungeonsBattle extends Component<DungeonsBattleProps, DungeonsBattleState> {
    constructor(props: DungeonsBattleProps) {
        super(props);
        this.state = {
            level: 1,
            name: "",
            currentStage: 1,
            dungeon: this.props.location.state,
            logcat: [],
            renderLog: [],
            playerTotalHp: this.props.player.equipmentProperty.health + props.player.currentPlayer.property.health,
            playerHp: this.props.player.equipmentProperty.health + props.player.currentPlayer.property.health,
            masterHp: 0,
            masterTotalHp: 1,
            drops: "",
            battleWin: false,
            battleStatus: BattleStatus.Prepare,
            finished: false
        }
    }

    componentDidMount() {
        GetDungeonInfo().then((res) => {
            if (res.data) {
                ModalUtils.HideLoading();
                Modal.clear();
                this.setState({
                    dungeon: res.data,
                    masterTotalHp: res.data.map.masters[0].master.property.health,
                    masterHp: res.data.map.masters[0].master.property.health,
                })
            } else {
                return ModalUtils.ShowSingleConfirm("提示", "发生错误", () => {
                    this.props.navigate(-1);
                })
            }
        })
    }

    doBattle = async () => {
        this.setState({
            logcat: [],
            renderLog: [],
            drops: "",
            battleWin: false,
            battleStatus: BattleStatus.DuringBattle
        })
        BattleDungeon(this.state.dungeon.id).then((res) => {
            if (res.data.finish) {
                this.setState({
                    finished: true
                })
            }
            var drops = res.data.drops;
            var content = []
            if (drops)
                content = drops.map((item) => {
                    return `${item.id === "10000" ? "经验值" : this.props.config.itemTemplateMap.get(item.id).name}x${item.amount}`
                });
            console.log( res.data.win)
            this.setState({
                logcat: res.data.logcat,
                renderLog: [],
                battleWin: res.data.win,
                drops: content.join(",")
            }, this.loopLog)
        })

    }


    loopLog = () => {
        if (this.state.renderLog.length >= this.state.logcat.length) {
            if(!this.state.battleWin){
                ModalUtils.ShowSingleConfirm("很遗憾", "战斗失败", () => {
                    this.props.navigate(-1);
                });
                return;
            }
            this.setState({
                battleStatus: BattleStatus.Finished
            })
            if (this.state.drops.length > 0) {

                if (this.state.finished) {
                    ModalUtils.ShowSingleConfirm("恭喜", "副本完成", () => {
                        this.props.navigate(-1);
                    });
                } else {
                    GetDungeonInfo().then((res) => {
                        this.setState({
                            dungeon: res.data,
                        })
                    })
                }
            }
            return
        }

        this.setState({
            playerHp: this.state.logcat[this.state.renderLog.length].selfHP,
            masterHp: this.state.logcat[this.state.renderLog.length].targetHP,
            renderLog: this.state.logcat.filter((v, i) => i <= this.state.renderLog.length),
        });
        setTimeout(this.loopLog, 500)
    }

    render() {
        var type = this.props.getQueryParams("type");
        var maps = type === "1" ? this.props.config.maps : this.props.config.dungeons;
        const {dungeon} = this.state;
        const {player} = this.props;
        var renders = [];
        this.state.renderLog.forEach((l) => renders.push(l))
        return <div className="battle-map-container">
            <NavBar onBack={() => this.props.navigate(-1)}
                    right={
                        <div>
                            <Button size={"small"} style={{}}
                                    disabled={this.state.battleStatus === BattleStatus.DuringBattle}
                                    onClick={this.doBattle}>开始战斗</Button>
                        </div>
                    }
                    style={{
                        height: "0.94rem",
                        background: "#09384D",
                        width: "100%",
                        boxSizing: "border-box"
                    }}>{this.state.name}{`${dungeon.map.regionName}${dungeon.map.name}${dungeon.level + 1}层`}</NavBar>

            <div style={{display: "flex", justifyContent: "flex-start", width: "100%", margin: "0.1rem"}}>
                <Avatar style={{width: "1.2rem", height: "1.2rem", marginLeft: "0.1rem"}}
                        src={GetPlayerIcon(this.props.player.currentPlayer.wuxing as WuXing)}></Avatar>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "4rem",
                    alignItems: "start",
                    padding: "0 0.1rem",
                    background: "#A91B1B",
                    justifyContent: "space-around",
                    boxSizing: "border-box",
                    marginLeft: "0.1rem",
                    border: "1px solid #F03F03"
                }}>
                    <div>{player.currentPlayer.nickname}</div>
                    <ProgressBar style={{width: "100%", '--fill-color': "#ff0000", "--track-color": "#616161"}}
                                 percent={this.state.playerHp / this.state.playerTotalHp * 100}></ProgressBar>
                    <div style={{
                        fontSize: "0.24rem",
                        marginBottom: "0.1rem"
                    }}>{this.state.playerHp}/{this.state.playerTotalHp}</div>
                </div>
            </div>
            <div style={{display: "flex", justifyContent: "end", width: "100%", margin: "0.1rem"}}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    padding: "0 0.1rem",
                    width: "4rem",
                    justifyContent: "space-around",
                    background: "#2B5B75",
                    marginRight: "0.1rem",
                    boxSizing: "border-box",
                    border: "1px solid #072335"
                }}>
                    <div>{dungeon.map.masters[0].master.name}</div>
                    <ProgressBar style={{
                        width: "100%",
                        '--fill-color': "#ff0000",
                        "--track-color": "#616161",
                        transform: "scaleX(-1)"
                    }} percent={100 * this.state.masterHp / this.state.masterTotalHp}></ProgressBar>
                    <div style={{
                        fontSize: "0.24rem",
                        marginBottom: "0.1rem"
                    }}>{this.state.masterHp}/{this.state.masterTotalHp}</div>
                </div>
                <Avatar style={{width: "1.2rem", height: "1.2rem", marginRight: "0.1rem"}}
                        src={`/images/icons/master/${dungeon.map.masters[0].master.icon}.png`}></Avatar>
            </div>

            <div>———————————— 战斗日志 ————————————</div>
            <div className="battle-logcat-container" style={{
                flex: 1,
                overflow: "scroll",
                width: "100%",
                padding: "0 0.2rem",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column"
            }}>
                {
                    this.state.battleStatus === BattleStatus.Finished && this.state.battleWin && <div
                        className="battle-logcat">获取奖励:{this.state.drops}</div>
                }
                {
                    renders.reverse().map((item, index) => <div
                        dangerouslySetInnerHTML={{__html: `${this.state.renderLog.length - index}:` + item.log}}
                        className="battle-logcat" key={index}></div>)
                }

            </div>
        </div>;
    }
}

export default withRouter(DungeonsBattle);
