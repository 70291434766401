import {makeAutoObservable} from "mobx";

export class CharacterTab {
    currentTab: string = "property";
    archiveFilter: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    UpdateCurrentTab(tab: string) {
        this.currentTab = tab;
    }

    UpdateArchiveFilter(filter: number) {
        this.archiveFilter = filter;
    }
}
