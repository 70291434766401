import {Component} from "react";

interface FormItemTitleProps {
    title: string;
    subTitle: string;
}

export default class FormItemTitle extends Component<FormItemTitleProps, any> {
    render() {
        return <div>
            <span style={{color: "white",fontSize:"0.34rem"}}>{this.props.title}</span>
            <span style={{color: "#888888",fontSize:"0.24rem",marginLeft:"0.16rem"}}>{this.props.subTitle}</span>
        </div>
    }
}
