import {Component} from "react";
import withRouter, {IRouterProps} from "../../utlis/withRouter";
import {Button, List, NavBar, Toast} from "antd-mobile";
import {ServerEntity} from "../../models/ServerEntity";
import ServerItem from "../../component/ServerItem/ServerItem";
import './index.less'
import {inject, observer} from "mobx-react";
import {IStore} from "../../stores";
import {APIHelper} from "../../utlis/APIHelper";
import * as localforage from "localforage";
import {ModalUtils} from "../../utlis/modalUtils";

interface ServerProps extends IRouterProps,IStore{

}

@inject("player","config")
@observer
class Server extends Component<ServerProps, any> {
    constructor(props) {
        super(props);
    }

    enterServer(server:ServerEntity){
        ModalUtils.ShowLoading();
        this.props.config.updateServer(server)
        this.props.player.getPlayerByServer(server.id).then(()=>{
            this.props.navigate({pathname:"/main"})
        }).catch((msg)=>{
            ModalUtils.ShowFailedMsg(msg);
            this.props.navigate({pathname:"/newCharacter"})
        }).finally(()=>{
            ModalUtils.HideLoading();
        });
    }

    render() {
        return <div>
            <NavBar back={null} style={{
                background: "var(--custom-navbar-background)",
                fontSize:"0.34rem"
            }} right={
                <a className="game-notice">公告</a>
            }>服务器选择</NavBar>
            <div className="server-container">
                {
                    this.props.config.servers.map((item)=>{
                        return <ServerItem onClick={(id)=>this.enterServer(item)} key={item.id} {...item}></ServerItem>
                    })
                }
            </div>
        </div>;
    }
}

export default withRouter(Server);
