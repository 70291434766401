import {
    Location, NavigateFunction, Params,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import React from "react";

export interface IRouterProps{
    location?:Location<any>;
    navigate?:NavigateFunction;
    params?:Params<any>;
    getQueryParams?:(key:string)=>string;
}

export default function withRouter<T extends IRouterProps>(Component: React.ComponentType<T>) {
    function ComponentWithRouterProp(props:T) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams<any>();
        let getQueryParams = function (key){
            return new URLSearchParams(location.search).get(key);
        };
        let router = {location,navigate,params,getQueryParams,...props}

        return <Component {...router}/>
    }
    return ComponentWithRouterProp;
}
