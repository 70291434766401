import {Component} from "react";
import withRouter, {IRouterProps} from "../../utlis/withRouter";
import {Button, Form, Input, Toast} from "antd-mobile";
import {FormInstance} from "antd-mobile/es/components/form";
import {APIHelper} from "../../utlis/APIHelper";
import FormItemTitle from "../../component/FormItemTitle";
import {ModalUtils} from "../../utlis/modalUtils";

class ChangePassword extends Component<IRouterProps, any> {
    constructor(props) {
        super(props);
    }

    form: FormInstance;
    onGoLoginClick = () => {
        this.props.navigate(-1);
    }
    onChangeClick = (values) => {
        this.form.validateFields().then((value) => {
            ModalUtils.ShowLoading();
            APIHelper.RegisterUser(value).then((res) => {
            }).catch((msg) => {
                ModalUtils.ShowFailedMsg(msg);
            }).finally(()=>{
                ModalUtils.HideLoading();
            })
        }).catch((reason) => {
            ModalUtils.ShowFailedMsg(reason);
        })
    }

    render() {
        return <div>
            <div style={{
                background: "#072335",
                height: "1.18rem",
                width: "100%",
                lineHeight: "1.18rem",
                fontSize: "0.34rem"
            }}>
                修改密码
            </div>

            <Form className="login-form" ref={(ref) => this.form = ref}
                  layout="vertical" onFinish={this.onChangeClick}>
                <Form.Item name="username" label={<FormItemTitle title="用户名" subTitle="修改密码的用户名"/>}>
                    <Input placeholder="请输入用户名"></Input>
                </Form.Item>
                <Form.Item name="password" label={<FormItemTitle title="密码" subTitle="输入账号的原始密码"/>}>
                    <Input type="password" placeholder="请输入密码"></Input>
                </Form.Item>
                <Form.Item name="password" label={<FormItemTitle title="新密码" subTitle="输入5-20位字符用于新密码"/>}>
                    <Input type="password" placeholder="请输入密码"></Input>
                </Form.Item>
                <Form.Item name="passwordConfirm" label={<FormItemTitle title="确认" subTitle="再次确认密码"/>}>
                    <Input type="password" placeholder="请再次输入密码"></Input>
                </Form.Item>

                <Form.Item style={{textAlign: "center"}}>
                    <Button style={{background: "#09384D", width: "3rem", height: "1rem"}}
                            type="submit">修改密码</Button>
                    <Button style={{background: "#09384D", width: "3rem", height: "1rem",marginLeft:"0.5rem"}}
                            onClick={this.onGoLoginClick} >返回</Button>
                </Form.Item>
            </Form>
        </div>;
    }
}

export default withRouter(ChangePassword);
