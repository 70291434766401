
export enum WuXing{
  Other = 0,
  Gold =1,//金
  Wood,//木
  Water,//水
  Fire,//火
  Earth//土
}


export function GetSchoolByWuxing(wuxing:WuXing){
  switch (wuxing){
    case WuXing.Other:
      return "无门派";
    case WuXing.Gold:
      return "天王";
    case WuXing.Wood:
      return "唐门";
    case WuXing.Water:
      return "段氏";
    case WuXing.Fire:
      return "丐帮";
    case WuXing.Earth:
      return "昆仑";

  }
}
