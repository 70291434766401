import {Component, Fragment} from "react";
import {inject, observer} from "mobx-react";
import withRouter, {IRouterProps} from "../../../utlis/withRouter";
import {IStore} from "../../../stores";
import {Button, Collapse, Ellipsis, Image, Modal, NavBar, Tabs, Toast} from "antd-mobile";
import {MapGroup} from "../../../stores/Config";
import {APIHelper} from "../../../utlis/APIHelper";
import GetDungeonInfo = APIHelper.GetDungeonInfo;
import StartDungeon = APIHelper.StartDungeon;
import {ModalUtils} from "../../../utlis/modalUtils";
import {TerritoryArea} from "../../../models/TerritoryArea";
import TerritoryItem from "../../../component/TerritoryItem";

interface MapProps extends IRouterProps, IStore {

}

interface TerritoryLevelState {
    currentLevel:number;
    territories:[]
}

@inject("player", "config")
@observer
class TerritoryLevel extends Component<MapProps, TerritoryLevelState> {
    constructor(props) {
        super(props);
        this.state = {
            territories: [],
            currentLevel:1
        }
    }

    componentDidMount() {
        this.props.config.refreshTerritory();
    }

    onBattleTerritoryClick = async (territory) => {
        const playerTerritory = await APIHelper.GetPlayerTerritory();
        if (playerTerritory.data){
            ModalUtils.ShowConfirm("提醒",`占领该领土后会放弃${playerTerritory.data.territory.name}`,()=>{
                ModalUtils.ShowLoading();
                APIHelper.BattleTerritory(territory).then(async (res) => {
                    if (res.data.logcat) {
                        this.props.navigate({pathname: "/main/battle/territory"}, {state: res.data});
                    } else {
                        await this.props.config.refreshTerritory();
                        ModalUtils.ShowSuccessMsg("占领成功");

                    }
                }).finally(()=>{
                    ModalUtils.HideLoading();
                });
            })
            return
        }
        ModalUtils.ShowLoading();
        APIHelper.BattleTerritory(territory).then(async (res) => {
            if (res.data.logcat) {
                this.props.navigate({pathname: "/main/battle/territory"}, {state: res.data});
            } else {
                await this.props.config.refreshTerritory();
                ModalUtils.ShowSuccessMsg("占领成功");

            }
        }).finally(()=>{
            ModalUtils.HideLoading();
        });
    }

    onClaimTerritoryClick = async (territory) => {
        ModalUtils.ShowLoading();
        APIHelper.ClaimTerritory(territory).then((res) => {
            var content = res.data.map((item) => {
                return {name: item.id === "10000" ? "经验值" : this.props.config.itemTemplateMap.get(item.id).name, ...item}
            });
            Modal.show({
                title: "结算成功",
                closeOnMaskClick: true,
                content: <Fragment>
                    {
                        content.map((c) => {
                            return <div key={c.id}>{`获得${c.name}:x${c.amount}`}</div>
                        })
                    }
                </Fragment>
            })
        }).catch((err)=>{
            ModalUtils.ShowFailedMsg(err);
        }).finally(()=>{
            ModalUtils.HideLoading();
        });
    }


    render() {
        const {player,config} = this.props;
        console.log(player.playerId);
        var type = this.props.getQueryParams("type");
        var maps = type === "1" ? this.props.config.maps : this.props.config.dungeons;
        return <div className="territory-details-container">
            <div className="territory-tabs">
                <Button onClick={() => this.setState({currentLevel: 1})}
                        className={`territory-tab-item ${this.state.currentLevel === 1 ? "active" : ""}`}
                        key="equipment">初级</Button>
                <Button onClick={() => this.setState({currentLevel: 2})}
                        className={`territory-tab-item ${this.state.currentLevel === 2 ? "active" : ""}`}
                        key="crafting">中级</Button>
                <Button onClick={() => this.setState({currentLevel: 3})}
                        className={`territory-tab-item ${this.state.currentLevel === 3 ? "active" : ""}`}
                        key="material">高级</Button>
                <div style={{flex: 1}}></div>
                <Button className="territory-tab-item"
                        onClick={() => this.props.navigate(-1)}>返回</Button>
            </div>
            {
                config.territories.filter((item) => {
                    return item.level === this.state.currentLevel
                }).map((item) => {
                    return <TerritoryItem key={item.id} data={item} territoryPlayer={{}}
                                          active={item.player.findIndex((i) => i.player.id === player.playerId) >= 0}
                                          onClaimClick={() => {
                                              this.onClaimTerritoryClick(item.id);
                                          }}
                                          onBossClick={() => {
                                              this.onBattleTerritoryClick(item.id);
                                          }}/>
                })
            }
        </div>;
    }
}

export default withRouter(TerritoryLevel);
