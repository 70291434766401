import React from "react";
import {Thames} from "./Thames";
import Player from "./Player";
import {Config} from "./Config";
import {CharacterTab} from "./tabs/CharacterTab";
import {BackpackTab} from "./tabs/BackpackTab";

const _Thames = new Thames();
const _Player = new Player();
const _Config = new Config();

export interface IStore{
    thames?:Thames;
    player?:Player;
    config?:Config
    tabs?:{
        character:CharacterTab,
        backpack:BackpackTab
    }
}

const stores:IStore = {
    thames:_Thames,
    player:_Player,
    config:_Config,
    tabs:{
        backpack:new BackpackTab(),
        character:new CharacterTab()
    }
}
export default stores;
