import {Component} from "react";
import {inject, observer} from "mobx-react";
import withRouter, {IRouterProps} from "../../../utlis/withRouter";
import {IStore} from "../../../stores";
import {Button, Ellipsis, Image, Modal, NavBar, Toast} from "antd-mobile";
import {MapGroup} from "../../../stores/Config";
import {APIHelper} from "../../../utlis/APIHelper";
import GetDungeonInfo = APIHelper.GetDungeonInfo;
import StartDungeon = APIHelper.StartDungeon;
import {ModalUtils} from "../../../utlis/modalUtils";

interface MapProps extends IRouterProps, IStore {

}

@inject("player", "config")
@observer
class Map extends Component<MapProps, any> {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        var type = this.props.getQueryParams("type");
        var maps = type === "1" ? this.props.config.maps : this.props.config.dungeons;
        var temp = []
        maps.forEach((item, key) => {
            temp.push(key)
        })
        this.setState({
            maps: temp
        })
    }


    onEnterDungeon(map: string) {
        ModalUtils.ShowLoading();
        GetDungeonInfo().then((res) => {
            if (res.data) {
                ModalUtils.HideLoading();
                Modal.clear();
                ModalUtils.ShowConfirm("提示", "有正在进行的副本，是否继续？", () => {
                    this.props.navigate({pathname: "/main/battle/dungeons"}, {state: res.data});
                });
            } else {
                return StartDungeon(map).then((res) => {
                    ModalUtils.HideLoading();
                    Modal.clear();
                    this.props.navigate({pathname: "/main/battle/dungeons"}, {state: res.data});
                })
            }
        })
        // this.props.navigate({pathname: "/main/battle/dungeons"}, {state: {"level": 1, "name": map.name}});
    }

    onEnterMapClick(map: MapGroup) {
        var type = this.props.getQueryParams("type");
        if (type === "1") {
            this.props.navigate({pathname: "/main/battle/map", search: `region=${map.name}&type=${type}`})
        } else {
            var l1 = map.map.subMap.find((item) => item.mapLevel === 1);
            var l2 = map.map.subMap.find((item) => item.mapLevel === 2);
            var l3 = map.map.subMap.find((item) => item.mapLevel === 3);
            Modal.show({
                bodyClassName: "dungeons-level-modal",
                closeOnMaskClick: true,
                header: null,
                className: "dungeons-modal",
                title: "请选择副本等级",
                showCloseButton: true,
                content: <div style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center"
                }}>
                    <Button
                        onClick={() => {
                            this.onEnterDungeon(l1.id);
                        }}
                        className="level-button">{`初级(Lv.${l1.minRequireLevel}——Lv.${Math.min(l1.maxRequireLevel, 150)})`}</Button>
                    <Button
                        onClick={() => {
                            this.onEnterDungeon(l2.id);
                        }}
                        className="level-button">{`中级(Lv.${l2.minRequireLevel}——Lv.${Math.min(l2.maxRequireLevel, 150)})`}</Button>
                    <Button
                        onClick={() => {
                            this.onEnterDungeon(l3.id);
                        }}
                        className="level-button">{`高级(Lv.${l3.minRequireLevel}——Lv.${Math.min(l3.maxRequireLevel, 150)})`}</Button>
                </div>
            })
        }
    }

    render() {
        var type = this.props.getQueryParams("type");
        var maps = type === "1" ? this.props.config.maps : this.props.config.dungeons;
        return <div className="battle-details-container">
            <NavBar onBack={() => this.props.navigate(-1)}
                    style={{
                        height: "0.94rem",
                        background: "#09384D",
                        width: "100%",
                        boxSizing: "border-box"
                    }}>{type === "1" ? "挂机地图" : "副本"}</NavBar>
            {
                maps.map((map) => {
                    return <div key={map.name} style={{
                        width: "7.26rem",
                        height: "1.12rem",
                        margin: "0.1rem 0 0",
                        display: "flex",
                        background: "#3B7082",
                        justifyContent: "space-between",
                        padding: "0.06rem",
                        boxSizing: "border-box"
                    }}>
                        <div style={{fontSize: "0.36rem", lineHeight: "1rem"}}>{map.name}</div>
                        <Button onClick={() => this.onEnterMapClick(map)} style={{
                            width: "0.8rem",
                            height: "0.64rem",
                            padding: "0",
                            fontSize: "0.28rem",
                            lineHeight: "0.64rem",
                            margin: "auto 0.12rem"
                        }}>进入</Button>
                    </div>
                })
            }
        </div>;
    }
}

export default withRouter(Map);
