import {action, makeAutoObservable} from "mobx";
import ItemEntity from "../models/ItemEntity";
import {ServerEntity} from "../models/ServerEntity";
import PlayerEntity from "../models/PlayerEntity";
import Stores from "./index";
import {APIHelper} from "../utlis/APIHelper";
import {PlayerItemEntity} from "../models/PlayerItemEntity";
import {PlayerDailyTaskEntity} from "../models/PlayerDailyTaskEntity";
import MapIdleEntity from "../models/MapIdleEntity";
import {Toast} from "antd-mobile";
import PropertyEntity from "../models/PropertyEntity";
import {PlayerClaimRewardEntity} from "../models/PlayerClaimRewardEntity";

export default class Player {
    constructor() {
        this.equipmentProperty = new PropertyEntity();
        makeAutoObservable(this);
    }

    userPlayerItems: Array<PlayerItemEntity> = [];
    currentPlayer: PlayerEntity;
    equippedItems: Map<number, PlayerItemEntity> = new Map<number, PlayerItemEntity>();
    dailyTasks: Array<PlayerDailyTaskEntity> = new Array<PlayerDailyTaskEntity>();
    claims:Array<PlayerClaimRewardEntity> = new Array<PlayerClaimRewardEntity>()
    mapIdleInfo: MapIdleEntity = null;
    playerId: string = "";
    equipmentProperty: PropertyEntity = new PropertyEntity();

    async Init() {
        return new Promise<any>(async (resolve, reject) => {
            if (Stores.config.currentServer) {
                await APIHelper.GetPlayerByServer(Stores.config.currentServer.id).then((res) => {
                    this.playerId = res.id;
                    return this.refreshPlayerInfo();
                }).catch(() => {
                    reject("login expired")
                })
                await this.refreshPlayerItems().then(() => {
                   return  APIHelper.ListPlayerDailyTask().then((data) => {
                        this.dailyTasks = data.data.tasks;
                        this.claims = data.data.claims;
                    })
                }).then(resolve).catch((err) => {
                    reject("login expired")
                });
            } else {
                reject("login expired")
            }
        })

    }

    async getPlayerByServer(serverId){
        return  APIHelper.GetPlayerByServer(Stores.config.currentServer.id).then((res) => {
            this.playerId = res.id;
            return this.refreshPlayerInfo();
        })
    }

    async refreshPlayerInfo() {
        await APIHelper.PlayerInfo(Stores.config.currentServer.id).then((res) => {
            this.updateCurrentPlayer(res);
        }).catch((e) => {
            console.log("用户未找到")
            console.error(e);
        })
    }


    updateCurrentPlayer(player: PlayerEntity) {
        this.currentPlayer = player;
        this.currentPlayer.playerSkills.sort((a, b) => a.skill.requireLevel - b.skill.requireLevel)
        var temps = new Map<number, PlayerItemEntity>();
        var tempProperty = new PropertyEntity();
        player.equipItems.forEach((equip) => {
            tempProperty.attack += equip.property.attack + equip.upgradeProperty.attack;
            tempProperty.criticalDamage += equip.property.criticalDamage + equip.upgradeProperty.criticalDamage;
            tempProperty.criticalRate += equip.property.criticalRate + equip.upgradeProperty.criticalRate;
            tempProperty.defense += equip.property.defense + equip.upgradeProperty.defense;
            tempProperty.damageBoost += equip.property.damageBoost + equip.upgradeProperty.damageBoost;
            tempProperty.defenseBoost += equip.property.defenseBoost + equip.upgradeProperty.defenseBoost;
            tempProperty.dodgeRate += equip.property.dodgeRate + equip.upgradeProperty.dodgeRate;
            tempProperty.health += equip.property.health + equip.upgradeProperty.health;
            tempProperty.hitRate += equip.property.hitRate + equip.upgradeProperty.hitRate;
            tempProperty.speed += equip.property.speed + equip.upgradeProperty.speed;
            tempProperty.wuxingNegative += equip.property.wuxingNegative + equip.upgradeProperty.wuxingNegative;
            tempProperty.wuxingPositive += equip.property.wuxingPositive + equip.upgradeProperty.wuxingPositive;
            temps.set(equip.item.type, equip);
        })
        this.equipmentProperty = tempProperty;
        this.equippedItems = temps;
    }

    async refreshPlayerItems() {
        await APIHelper.ListPlayerItem().then((data) => {
            this.userPlayerItems = data.data;
        })
    }

    async refreshIdleInfo() {
        APIHelper.GetIdleInfo().then((res) => {
            this.mapIdleInfo = res.data;
        }).catch((err) => {
            return Promise.reject(err);
        })
    }

    async beginIdle(map: string) {
        return APIHelper.StartIdle({mapId: map}).then((res) => {
            this.mapIdleInfo = res.data;
        }).catch((err) => {
            return Promise.reject(err);
        })
    }

    async endIdle(map: string) {
        return APIHelper.EndIdle({mapId: map}).then(async (res) => {
            await this.refreshIdleInfo()
            return res.data;
        }).catch((err) => {
            return Promise.reject(err);
        })
    }

}
