import {Entity} from "./Entity";

export default class PropertyEntity extends Entity{
    /**
     * 攻击
     */
    type: number = 0;
    /**
     * 攻击
     */
    attack: number = 0;

    /**
     * 生命
     */
    health: number = 0;

    /***
     * 防御
     */
    defense: number = 0;

    /**
     * 命中率
     */
    hitRate: number = 0;

    /**
     * 闪避率
     */
    dodgeRate: number = 0;

    /**
     * 暴击率
     */
    criticalRate: number = 0;

    /**
     * 暴击伤害
     */
    criticalDamage: number = 0;

    /**
     * 速度
     */
    speed: number = 0;

    /**
     * 五行增益
     */
    wuxingPositive: number = 0;

    /**
     * 五行减益
     */
    wuxingNegative: number = 0;

    /**
     * 伤害增强
     */
    damageBoost: number = 0;

    /**
     * 伤害增强
     */
    defenseBoost: number = 0;
}
