import {Component} from "react";
import {ServerEntity} from "../../models/ServerEntity";
import './index.less'
import {Button} from "antd-mobile";

class ServerItemProps extends ServerEntity{
    onClick:Function;
}

export default class ServerItem extends Component<ServerItemProps, any> {


    render() {
        return <Button onClick={()=>this.props.onClick()} className="server-item">
            {this.props.serverName}
            <span className="server-item-tag">火爆</span>
        </Button>;
    }
}
