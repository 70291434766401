import {Modal, Stepper, Toast} from "antd-mobile";
import {APIHelper} from "./APIHelper";
import {ScaleLoader} from 'react-spinners'
import "./modal.less"
import {ModalShowHandler} from "antd-mobile/es/components/modal";

export module ModalUtils {
    export function ShowConfirm(title: string, content: string, onOk: Function, onCancel: Function = null) {
        Modal.show({
            bodyClassName: "confirm-modal",
            closeOnMaskClick: true,
            header: null,
            content: <div style={{height: "100%"}}>
                <div className="top-content">
                    <div>{title}</div>
                    <div>{content}</div>
                </div>
                <div style={{
                    height: "0.8rem",
                    position: "absolute",
                    bottom: "3px",
                    width: "5.28rem",
                    display: "flex",
                    boxSizing: "border-box",
                }}>
                    <a onClick={async () => {
                        onOk && onOk()
                        Modal.clear();
                    }
                    } style={{
                        flex: 1,
                        borderTop: "solid 1px #787332",
                        color: "white",
                        borderRight: "solid 1px #787332",
                        fontSize: "0.36rem",
                        fontWeight: "600",
                        textAlign: "center",
                        lineHeight: "0.8rem"
                    }}>确定</a>
                    <a onClick={() => {
                        Modal.clear();
                        onCancel && onCancel()
                    }} style={{
                        flex: 1,
                        borderTop: "solid 1px #787332",
                        color: "white",
                        fontSize: "0.36rem",
                        fontWeight: "600",
                        textAlign: "center",
                        lineHeight: "0.8rem"
                    }}>关闭</a>
                </div>
            </div>
        })
    }

    export function ShowSingleConfirm(title: string, content: string, onOk: Function = null) {
        Modal.show({
            bodyClassName: "confirm-modal",
            closeOnMaskClick: true,
            header: null,
            content: <div style={{height: "100%"}}>
                <div className="top-content">
                    <div>{title}</div>
                    <div>{content}</div>
                </div>
                <div style={{
                    height: "0.8rem",
                    position: "absolute",
                    bottom: "3px",
                    width: "5.28rem",
                    display: "flex",
                    boxSizing: "border-box",
                }}>
                    <a onClick={async () => {
                        onOk && onOk()
                        Modal.clear();
                    }
                    } style={{
                        flex: 1,
                        borderTop: "solid 1px #787332",
                        color: "white",
                        borderRight: "solid 1px #787332",
                        fontSize: "0.36rem",
                        fontWeight: "600",
                        textAlign: "center",
                        lineHeight: "0.8rem"
                    }}>确定</a>
                </div>
            </div>
        })
    }

    var loadingModal: ModalShowHandler;

    export function ShowSuccessMsg(msg:string){
        Toast.show({
            icon: 'success',
            content: msg,
            duration: 1000
        })
    }

    export function ShowFailedMsg(msg:string){
        Toast.show({
            icon: 'fail',
            content: msg,
            duration: 1000
        })
    }

    export function ShowLoading() {
        if (loadingModal){
            return;
        }
        loadingModal = Modal.show({
            header: null,
            content: <ScaleLoader color={"#ffffff"}></ScaleLoader>,
            bodyStyle:{
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                backgroundColor:"transparent"
            }
        })
    }

    export function HideLoading() {
        if (!loadingModal) return;
        loadingModal.close();
        loadingModal = null;
    }
}
