import {Component} from "react";

export default class Chat extends Component<any, any>{
    constructor(props) {
        super(props);
    }

    render() {
        return <div>
            消息页面
        </div>;
    }
}
