import React, {Component} from "react";
import withRouter, {IRouterProps} from "../../utlis/withRouter";
import {CapsuleTabs, NavBar, TabBar, Tabs} from "antd-mobile";
import "./index.css"
import {
    AppOutline,
    MessageOutline,
    UnorderedListOutline,
    ChatAddOutline,
    UserOutline,
} from 'antd-mobile-icons'
import {Navigate, Route, Routes} from "react-router-dom";
import Character from "../tabs/character";
import {DatabaseOutlined, HeatMapOutlined, MenuOutlined} from "@ant-design/icons";
import CustomHeaderBar from "../../component/CustomHeaderBar";
import Backpack from "../tabs/backpack";
import Battle from "../tabs/battle";
import Chat from "../tabs/chat";
import Menus from "../tabs/menus";
import Map from "../tabs/battle/map";
import MapDetail from "../tabs/battle/mapDetail";
import CraftList from "../tabs/backpack/CraftEquipList";
import TaskPage from "../tabs/task";
import CraftItemList from "../tabs/backpack/CraftItemList";
import MapBossBattle from "../tabs/battle/mapBossBattle";
import DungeonsBattle from "../tabs/battle/dungeonsBattle";
import TerritoryLevel from "../tabs/battle/territoryLevel";
import SongjinZhanchang from "../tabs/battle/songjinZhanchang";
import TerritoryBattle from "../tabs/battle/territoryBattle";
import SongjinBattle from "../tabs/battle/songjinBattle";
import MenpaiBattle from "../tabs/battle/menpaiBattle";
import MenpaiInfo from "../tabs/battle/menpaiInfo";

class Main extends Component<IRouterProps, any> {
    constructor(props:IRouterProps) {
        super(props);
    }
    tabs = [
        {
            key: '/cha',
            title: '角色',
            icon: <UserOutline />,
        },
        {
            key: '/backpack',
            title: '背包',
            icon: <DatabaseOutlined />,
        },
        {
            key: '/battle',
            title: '战斗',
            icon: <HeatMapOutlined />,
        },
        {
            key: '/message',
            title: '消息',
            icon: <MessageOutline />,
        },
        {
            key: '/task',
            title: '任务',
            icon: <ChatAddOutline />,
        },
        {
            key: '/menu',
            title: '菜单',
            icon: <MenuOutlined />,
        },
    ]

    getCurrentActiveKey(){
       var pathName = this.props.location.pathname;
       console.log(pathName)
       switch (pathName){
           case "/cha":
               return "/cha";
           case "/main/tabs/backpack":
               return "/backpack";
           case "/main/tabs/battle":
               return "/battle";
           case "/message":
               return "/message";
           case "/task":
               return "/task";
       }
    }

    renderMainWithTabs(){
        return <div style={{height:"100%",display:"flex",flexDirection:"column"}}>
            <div style={{flex:"1",overflow:"hidden"}}>
                <Routes>
                    <Route path="/" element={<Navigate to={"/main/tabs/cha"} />} />
                    <Route path="cha" element={<Character/>}></Route>
                    <Route path="backpack" element={<Backpack/>}></Route>
                    <Route path="battle" element={<Battle/>}></Route>
                    <Route path="message" element={<Chat/>}></Route>
                    <Route path="task" element={<TaskPage/>}></Route>
                    <Route path="menu" element={<Menus/>}></Route>
                </Routes>
            </div>
            <TabBar activeKey={this.getCurrentActiveKey()} onChange={(key)=>this.props.navigate({
                pathname:"/main/tabs" + key
            },{})} className="main-tabs-bar">
                {this.tabs.map(item => (
                    <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                ))}
            </TabBar>
        </div>
    }

    render() {
        return <div style={{display:"flex",flexDirection:"column",height:"100%"}}>
            <CustomHeaderBar/>
            <div style={{flex:"1",overflow:"hidden",position:"relative"}}>
                <Routes>
                    <Route path="/" element={<Navigate to={"/main/tabs/cha"} />} />
                    <Route path="tabs/*" element={this.renderMainWithTabs()} />
                    <Route path="backpack/craftEquip" element={<CraftList/>}></Route>
                    <Route path="backpack/craftItem" element={<CraftItemList/>}></Route>
                    <Route path="battle/maps" element={<Map/>}></Route>
                    <Route path="battle/map" element={<MapDetail/>}></Route>
                    <Route path="battle/details" element={<MapBossBattle/>}></Route>
                    <Route path="battle/dungeons" element={<DungeonsBattle/>}></Route>
                    <Route path="battle/territoryLevel" element={<TerritoryLevel/>}></Route>
                    <Route path="battle/songjinSign" element={<SongjinZhanchang/>}></Route>
                    <Route path="battle/territory" element={<TerritoryBattle/>}></Route>
                    <Route path="battle/songjin" element={<SongjinBattle/>}></Route>
                    <Route path="battle/menpaiSign" element={<MenpaiInfo/>}></Route>
                    <Route path="battle/menpai" element={<MenpaiBattle/>}></Route>

                </Routes>
            </div>


        </div>;
    }
}
export default withRouter(Main);
