import {Component} from "react";
import {Avatar} from "antd-mobile";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import getStoreFromProps from "../../utlis/getStoreFromProps";
import "./index.less"
import {GetPlayerIcon} from "../../utlis/AssetsUtils";

@inject("player")
@observer
export default class CustomHeaderBar extends Component<any, any> {
    private player = getStoreFromProps(this.props, "player");

    componentDidMount() {
        console.log(this.props)
    }

    render() {
        const {currentPlayer} = this.player;
        return <div className="custom-header">
            <Avatar style={{width: "0.98rem", height: "0.98rem"}}
                    src={GetPlayerIcon(this.player.currentPlayer.wuxing)}/>
            <div className="right-info">
                <div className="line1-con">
                    <div>
                        {currentPlayer.nickname}
                    </div>
                    <div style={{display: "flex"}}>
                        <div style={{lineHeight: "0.4rem", marginRight: "0.1rem"}}>
                            {currentPlayer.coins || 0}
                        </div>
                        <img src="/images/ui/coins_icon.png"/>
                    </div>
                </div>
                <div className="line2-con">
                    <div>
                        Lv.{currentPlayer.level}
                    </div>
                    <div style={{display: "flex"}}>
                        <div style={{lineHeight: "0.4rem", marginRight: "0.1rem"}}>
                            {currentPlayer.vitality || 0}
                        </div>
                        <img src="/images/ui/vitality_icon.png"/>
                    </div>
                </div>
            </div>
        </div>
    }
}
