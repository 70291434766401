import React, {Component} from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter, HashRouter, Route, Routes} from "react-router-dom";
import Login from "./pages/login";
import Splash from "./pages/splash";
import Server from "./pages/server";
import Main from "./pages/main";
import CharacterCreate from "./pages/characterCreate";
import Register from "./pages/register";
import ChangePassword from "./pages/register/changePassword";
import RetrivePassword from "./pages/register/retrivePassword";
import stores from "./stores";
import withRouter, {IRouterProps} from "./utlis/withRouter";
import {Toast} from "antd-mobile";

interface AppState{
    init:boolean

}
class App extends Component<IRouterProps, AppState> {
    constructor(props) {
        super(props);
        this.state = {
            init:false
        }
    }
    componentDidMount() {
        stores.player.Init().then(()=>{
            this.setState({
                init:true
            },()=>{
                setTimeout(()=>this.props.navigate({pathname:"/main"}))

            })
        }).catch((msg)=>{
            this.setState({
                init:true
            },()=>{
                setTimeout(()=>this.props.navigate({pathname:"/"}))
            })
        });
    }

    render() {
        if (!this.state.init) return ;
        return (
            <div className="App">
                <Routes>
                    <Route path="" element=<Splash/> ></Route>
                    <Route path="/login" element=<Login/> ></Route>
                    <Route path="/register" element=<Register/> ></Route>
                    <Route path="/change" element=<ChangePassword/> ></Route>
                    <Route path="/retrive" element=<RetrivePassword/> ></Route>
                    <Route path="/server" element=<Server/> ></Route>
                    <Route path="/main/*" element=<Main/> ></Route>
                    <Route path="/newCharacter" element=<CharacterCreate/>></Route>
                </Routes>
            </div>
        );
    }
}

export default withRouter(App);
