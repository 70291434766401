import {Component} from "react";
import {Button, Ellipsis, Image} from "antd-mobile";
import SkillEntity from "../../models/SkillEntity";
import ItemEntity from "../../models/ItemEntity";

interface BattleButtonProps {
    title:string;
    description:string;
    onClick:()=>void;
}

export default class BattleButton extends Component<BattleButtonProps, any> {

    render() {
        return <div onClick={()=>this.props.onClick()} style={{width:"7rem",height:"1.16rem",margin:"0.08rem 0 0",display:"flex",background:"#09384D",padding:"0.06rem",boxSizing:"border-box",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
            <div style={{fontSize:"0.36rem",fontWeight:"bold"}}>{this.props.title}</div>
            <div style={{fontSize:"0.24rem",color:"#999999"}}>{this.props.description}</div>
        </div>;
    }
}
