import {Component, Fragment} from "react";
import {AlignRightOutlined, ArrowRightOutlined} from "@ant-design/icons";

interface EquipPropertyProps {
    title: string;
    value: number;
    color?: string;
    toValue?: number;
}

export default class EquipProperty extends Component<EquipPropertyProps, any> {
    constructor(props: any) {
        super(props);
    }


    render() {
        return <div>
            <span style={{color: this.props.color, fontSize: "0.24rem"}}>{this.props.title}:</span> <span
            style={{color: "white", fontSize: "0.24rem"}}>{this.props.value}</span>
            {
                this.props.toValue !== undefined && this.props.toValue > 0 && <Fragment>
                    <ArrowRightOutlined style={{width: "0.24rem", height: "0.24rem", margin: "0.1rem 0"}}/>
                    <span
                        style={{color: "white", fontSize: "0.24rem"}}>{this.props.toValue}</span>
                </Fragment>
            }
        </div>;
    }
}
