import {Component} from "react";
import withRouter, {IRouterProps} from "../../utlis/withRouter";
import {Button, Form, Input, Toast} from "antd-mobile";
import {FormInstance} from "antd-mobile/es/components/form";
import {APIHelper} from "../../utlis/APIHelper";
import FormItemTitle from "../../component/FormItemTitle";
import {ModalUtils} from "../../utlis/modalUtils";

class RetrivePassword extends Component<IRouterProps, any> {
    constructor(props) {
        super(props);
    }

    form: FormInstance;
    onGoLoginClick = () => {
        this.props.navigate(-1);
    }
    onRetriveClick = (values) => {
        ModalUtils.ShowLoading();
        this.form.validateFields().then((value) => {
            APIHelper.RegisterUser(value).then((res) => {
                console.log(value)
            }).catch((msg) => {
                ModalUtils.ShowFailedMsg(msg);
            }).finally(()=>{
                ModalUtils.HideLoading();
            })
        }).catch((reason) => {
            ModalUtils.ShowFailedMsg(reason);
        })
    }

    render() {
        return <div>
            <div style={{
                background: "#072335",
                height: "1.18rem",
                width: "100%",
                lineHeight: "1.18rem",
                fontSize: "0.34rem"
            }}>
                找回密码
            </div>

            <Form className="login-form" ref={(ref) => this.form = ref}
                  layout="vertical" onFinish={this.onRetriveClick}>
                <Form.Item name="username" label={<FormItemTitle title="账号" subTitle="输入你需要找回密码的账号"/>}>
                    <Input placeholder="请输入账号"></Input>
                </Form.Item>
                <Form.Item name="username" label={<FormItemTitle title="邮箱" subTitle="输入注册账号时的邮箱"/>}>
                    <Input placeholder="请输入邮箱"></Input>
                </Form.Item>
                <Form.Item
                    extra={<Button style={{
                        background: "#09384D",
                        width: "2.13rem",
                        height: "0.8rem",
                        border: "none",
                        marginTop: "0.6rem"
                    }}
                                   type="submit">找回密码</Button>} style={{display: "flex"}} name="code"
                    label={<FormItemTitle title="邮箱" subTitle="输入收到的验证码"/>}>
                    <Input style={{width: "4.83rem"}} placeholder="请输入验证码"></Input>

                </Form.Item>
                <Form.Item name="password" label={<FormItemTitle title="新密码" subTitle="输入5-20位字符"/>}>
                    <Input type="password" placeholder="请输入密码"></Input>
                </Form.Item>
                <Form.Item name="passwordConfirm" label={<FormItemTitle title="确认" subTitle="再次确认密码"/>}>
                    <Input type="password" placeholder="请再次输入密码"></Input>
                </Form.Item>


                <Form.Item style={{textAlign: "center"}}>
                    <Button style={{background: "#09384D", width: "3rem", height: "1rem"}}
                            type="submit">找回密码</Button>
                    <Button style={{background: "#09384D", width: "3rem", height: "1rem", marginLeft: "0.5rem"}}
                            onClick={this.onGoLoginClick}>返回</Button>
                </Form.Item>
            </Form>
        </div>;
    }
}

export default withRouter(RetrivePassword);
