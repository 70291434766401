import {WuXing} from "./WuXing";

export enum ItemType {
  Weapon = 1,
  Necklace = 2,
  Ring = 3,
  JadePendant = 4,
  Talisman = 5,
  Helmet = 6,
  Cloth = 7,
  Belt = 8,
  Bracers = 9,
  Shoe = 10,
  Cloak = 11,
  LevelSheet,
  WuxingSheet,
  XuanJing,
  Mineral,
  Clothes,
  Other,
  Special = 99
}

export function GetItemTypeName(type:ItemType){
  switch (type){
    case ItemType.Weapon:
      return "武器";
    case ItemType.Necklace:
      return "项链";;
    case ItemType.Ring:
      return "戒指";
    case ItemType.JadePendant:
      return "玉佩";
    case ItemType.Talisman:
      return "护符";
    case ItemType.Helmet:
      return "帽子";
    case ItemType.Cloth:
      return "衣服";
    case ItemType.Belt:
      return "腰带";
    case ItemType.Bracers:
      return "护腕";
    case ItemType.Shoe:
      return "鞋子";
    case ItemType.Cloak:
      return "披风";
    case ItemType.LevelSheet:
      return "官印";
    case ItemType.WuxingSheet:
      return "五行印";
    case ItemType.XuanJing:
      return "玄晶";
    case ItemType.Mineral:
      return "矿石";
    case ItemType.Clothes:
      return "布料";
    case ItemType.Other:
      return "其他";

  }
}

export function ItemTypeList(){
  return [
    {
      type:ItemType.Weapon,
      name:"武器"
    },
    {
      type:ItemType.Necklace,
      name:"项链"
    },
    {
      type:ItemType.Ring,
      name:"戒指"
    },
    {
      type:ItemType.JadePendant,
      name:"玉佩"
    },
    {
      type:ItemType.Talisman,
      name:"护符"
    },
    {
      type:ItemType.Helmet,
      name:"帽子"
    },
    {
      type:ItemType.Cloth,
      name:"衣服"
    },
    {
      type:ItemType.Belt,
      name:"腰带"
    },
    {
      type:ItemType.Bracers,
      name:"护腕"
    },
    {
      type:ItemType.Shoe,
      name:"鞋子"
    },
    {
      type:ItemType.Cloak,
      name:"披风"
    },
    {
      type:ItemType.LevelSheet,
      name:"官印"
    },
    {
      type:ItemType.WuxingSheet,
      name:"五行印"
    },
    {
      type:ItemType.XuanJing,
      name:"玄晶"
    },
    {
      type:ItemType.Mineral,
      name:"矿石"
    },
    {
      type:ItemType.Clothes,
      name:"布料"
    },
    {
      type:ItemType.Other,
      name:"其他"
    },
  ]
}

export enum ItemTopType{
  AttackWeapon,
  DefenseWeapon,
  OtherWeapon,
  Material
}

export function GetItemType(itemType:ItemType){
  switch (itemType) {
    case ItemType.Weapon:
    case ItemType.Necklace:
    case ItemType.Ring:
    case ItemType.JadePendant:
    case ItemType.Talisman:
      return ItemTopType.AttackWeapon;
    case ItemType.Helmet:
    case ItemType.Cloth:
    case ItemType.Belt:
    case ItemType.Bracers:
    case ItemType.Shoe:
      return ItemTopType.DefenseWeapon;
    case ItemType.Cloak:
    case ItemType.LevelSheet:
    case ItemType.WuxingSheet:
      return ItemTopType.OtherWeapon;
    case ItemType.XuanJing:
    case ItemType.Mineral:
    case ItemType.Clothes:
    case ItemType.Other:
      return ItemTopType.Material;
  }
}
