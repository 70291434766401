import {makeAutoObservable} from "mobx";

export class BackpackTab{
    currentTab: string = "equipment";
    currentFilter = "all";
    constructor() {
        makeAutoObservable(this);
    }

    UpdateCurrentTab(tab:string){
        this.currentTab = tab;
    }

    UpdateFilter(filter:string){
        this.currentFilter = filter;
    }
}
