import {Component} from "react";

interface PropertyItemProps {
    title: string;
    value: any;
    additional?: string
    addAble?: boolean;
    onAdd?: () => void
}

export default class PropertyItem extends Component<PropertyItemProps, any> {

    render() {
        return <div className="property-item" style={{display: "flex", justifyContent: "space-between"}}>
            <div style={{color: "#F6C509"}}>{this.props.title}</div>
            <div style={{display: "flex", flexDirection: "row"}}>
                <span>{this.props.value}</span>
                <span>{this.props.additional}</span>{
                this.props.addAble && <div onClick={this.props.onAdd} style={{
                    background: "#2C4F62",
                    width: "0.36rem",
                    height: "0.36rem",
                    borderRadius: "0.36rem",
                    lineHeight: "0.36rem",
                    margin: "0.1rem 0.1rem"
                }}>+</div>

            }
            </div>
        </div>;
    }
}
