import {Component} from "react";
import {Button} from "antd-mobile";
import withRouter, {IRouterProps} from "../../../utlis/withRouter";
import "./index.less"
import localforage from "localforage";
import {toJS} from "mobx";
import {inject, observer} from "mobx-react";
import {IStore} from "../../../stores";

interface MenusProps extends IRouterProps,IStore{}

@inject("player", "config", "tabs")
@observer
class Menus extends Component<MenusProps, any> {
    constructor(props) {
        super(props);
    }

    logout = async () => {
       await localforage.clear();
        this.props.navigate({
            pathname: "/",
        }, {
            replace: true
        })

    }

    switchPlayer = async () => {
        await this.props.config.updateServer(null);
        this.props.navigate({
            pathname: "/server",
        }, {
            replace: true
        })

    }

    render() {
        return <div className="menu-details-container">
            <div className="menu-button-row">
                <Button className="menu-button" onClick={this.logout}>退出登录</Button>
                <div style={{width:"0.1rem"}}></div>
                <Button className="menu-button" onClick={this.switchPlayer}>切换角色</Button>
            </div>
            <div className="menu-button-row">
                <Button className="menu-button" onClick={()=>window.location.reload()}>资源重载</Button>
            </div>
        </div>;
    }
}

export default withRouter(Menus);
