import {Component} from "react";
import BattleButton from "../../../component/BattleButton";
import "./index.less"
import withRouter, {IRouterProps} from "../../../utlis/withRouter";
import {Button, Modal, Toast} from "antd-mobile";
import {MapGroup} from "../../../stores/Config";
import {ModalUtils} from "../../../utlis/modalUtils";

class Battle extends Component<IRouterProps, any> {
    constructor(props) {
        super(props);
    }

    onEnterSongjinClick(map: MapGroup) {
        var type = this.props.getQueryParams("type");
        if (type === "1") {
            this.props.navigate({pathname: "/main/battle/map", search: `region=${map.name}&type=${type}`})
        } else {
            var l1 = map.map.subMap.find((item) => item.mapLevel === 1);
            var l2 = map.map.subMap.find((item) => item.mapLevel === 2);
            var l3 = map.map.subMap.find((item) => item.mapLevel === 3);
            Modal.show({
                bodyClassName: "dungeons-level-modal",
                closeOnMaskClick: true,
                header: null,
                className: "dungeons-modal",
                title: "请选择副本等级",
                showCloseButton: true,
                content: <div style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center"
                }}>
                    <Button
                        onClick={() => {
                        }}
                        className="level-button">{`初级(Lv.${l1.minRequireLevel}——Lv.${Math.min(l1.maxRequireLevel, 150)})`}</Button>
                    <Button
                        onClick={() => {
                        }}
                        className="level-button">{`中级(Lv.${l2.minRequireLevel}——Lv.${Math.min(l2.maxRequireLevel, 150)})`}</Button>
                    <Button
                        onClick={() => {
                        }}
                        className="level-button">{`高级(Lv.${l3.minRequireLevel}——Lv.${Math.min(l3.maxRequireLevel, 150)})`}</Button>
                </div>
            })
        }
    }

    onEnterSongjinZhanchang = ()=>{
        this.props.navigate({pathname: "/main/battle/songjinSign"});
    }

    onEnterMenpai = ()=>{
        this.props.navigate({pathname: "/main/battle/menpaiSign"});
    }

    render() {
        return <div className="battle-details-container">
            <img src="/images/ui/pvp.png" style={{width: "6.2rem", height: "0.7rem"}}/>
            <BattleButton title="地图挂机" description="永久免费离线挂机" onClick={() => {
                this.props.navigate({pathname: "/main/battle/maps", search: "type=1"}, {state: 1})
            }}></BattleButton>
            <BattleButton title="副本" description="伤害就是前行的能力" onClick={() => {
                this.props.navigate({pathname: "/main/battle/maps", search: "type=2"}, {state: 1})
            }}></BattleButton>
            <img src="/images/ui/pve.png" style={{width: "6.2rem", height: "0.7rem"}}/>
            <BattleButton title="宋金战场" description="宋金战场" onClick={this.onEnterSongjinZhanchang}></BattleButton>
            <BattleButton title="门派竞技" description="门派竞技" onClick={this.onEnterMenpai}></BattleButton>
            <BattleButton title="领土战争" description="领土战争" onClick={() => {
                this.props.navigate({pathname: "/main/battle/territoryLevel", search: "type=2"}, {state: 1})
            }}></BattleButton>
        </div>;
    }
}

export default withRouter(Battle);
